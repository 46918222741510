body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .App {
    text-align: center;
  }
  
  video {
    width: 300px;
    height: 200px;
    margin: 10px;
  }
  
  input {
    margin: 5px;
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  button {
    padding: 10px 15px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  